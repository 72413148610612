import { useNavigate } from "react-router-dom";
import Button from "../../button/button";
import "./_menue.scss";

export default function Menue() {
  const navigate = useNavigate();

  const handleButtonClick = (name: string) => {
    navigate(`/${name}`);
  };
  console.log(window.location.pathname);

  window.addEventListener("message", (event) => {
    const { action } = event.data;

    if (action === "goBack" && window.location.pathname === "/menue") {
      window.parent.postMessage({ closeApps: true }, "*");
    }
  });

  return (
    <div
      style={{ backgroundImage: "url(/background/first-frame-bg.png)" }}
      className="container menue_container"
    >
      <div className="button_wrap">
        <Button onClick={() => handleButtonClick("privacy")}>Privacy</Button>
        <Button onClick={() => handleButtonClick("games")}>Games</Button>

        <Button
          onClick={() => window.parent.postMessage({ exitEvent: true }, "*")}
        >
          Exit
        </Button>
      </div>
    </div>
  );
}
