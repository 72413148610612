import { useNavigate } from "react-router-dom";
import "./_privacy.scss";

export default function PrivacyPage() {
  const navigate = useNavigate();

  window.addEventListener("message", (event) => {
    const { action } = event.data;
    if (action === "goBack") {
      navigate(`/menue`);
    }
  });
  return (
    <div
      className="container privacy_container"
      style={{ backgroundImage: "url(/background/first-frame-bg.png)" }}
    >
      <div className="wrap">
        <h3 className="title">Privacy Policy</h3>
        <p className="text">
          Welcome to the Treasure Trail app ("App"), provided to you as a free
          service. This Privacy Policy outlines how we handle your data while
          using the App, which is provided "AS IS" without any guarantees or
          obligations. We are committed to protecting your privacy and ensuring
          a secure and enjoyable experience.
          <br />
          <br />
          Information Collection and Use
          <br />
          <br />
          During the download and use of the App, the following information may
          be collected:
          <br />
          <br />
          - Your device's IP address
          <br />
          - The pages you visit in the App, including the time, date, and
          duration of your visit
          <br />
          - The operating system of your mobile device
          <br />
          - Additional usage data
          <br />
          <br />
          The Service Provider may use this information to periodically contact
          you regarding important updates, necessary notifications, and
          marketing promotions.
          <br />
          <br />
          To improve your experience, the Service Provider may request certain
          personal information, which will be retained and used in accordance
          with this Privacy Policy.
          <br />
          <br />
          The App uses libraries that may access your approximate location to
          improve functionality and tailor advertising to your interests. You
          can modify your location permissions at any time in your device
          settings.
          <br />
          <br />
          Opt-Out Rights
          <br />
          <br />
          You can stop all data collection by the App by uninstalling it. You
          may use the standard uninstall processes available on your mobile
          device or through the app marketplace.
          <br />
          <br />
          Data Retention Policy
          <br />
          <br />
          The Service Provider will retain user-provided data for as long as you
          use the App and for a reasonable period afterward. If you wish to
          delete data provided via the App, please contact them at
          game.support.link@proton.me, and they will respond within a reasonable
          time.
          <br />
          <br />
          Third-Party Access
          <br />
          <br />
          Aggregated, anonymized data may periodically be transmitted to
          external services to help the Service Provider improve the App and its
          services. The Service Provider may share your information with third
          parties as described in this Privacy Policy.
          <br />
          <br />
          Please note that the App uses third-party services that have their own
          privacy policies regarding data handling. Below are links to the
          privacy policies of the third-party service providers used by the App:
          <br />
          <br />-{" "}
          <a href="https://policies.google.com/privacy">Google Play Services</a>
          <br />
          <br />
          The Service Provider may disclose user-provided and automatically
          collected information:
          <br />
          <br />
          - As required by law, such as to comply with a subpoena or similar
          legal process;
          <br />
          - When they believe in good faith that disclosure is necessary to
          protect their rights, your safety, or the safety of others,
          investigate fraud, or respond to a government request;
          <br />
          - With trusted service providers who work on their behalf, who do not
          have an independent right to use the disclosed information, and have
          agreed to adhere to the rules set forth in this privacy statement.
          <br />
          <br />
          Children
          <br />
          <br />
          The Service Provider does not collect data from or market to children
          under 13 years of age. If we become aware that a child under 13 has
          provided personal information, it will be immediately deleted from our
          servers. If you are a parent or guardian and know that your child has
          provided personal information, please contact us
          (game.support.link@proton.me) so that we can take appropriate action.
          <br />
          <br />
          Security
          <br />
          <br />
          The Service Provider is committed to protecting the confidentiality of
          your information and implements physical, electronic, and procedural
          safeguards to protect the data processed and maintained.
          <br />
          <br />
          Changes
          <br />
          <br />
          This Privacy Policy may be updated periodically for any reason. We
          will notify you of any changes by updating this page. It is
          recommended that you review this Privacy Policy regularly, as
          continued use of the App constitutes acceptance of all changes.
          <br />
          <br />
          This Privacy Policy is effective as of September 6, 2024.
          <br />
          <br />
          Your Consent
          <br />
          <br />
          By using the App, you consent to the processing of your data as
          described in this Privacy Policy and its amendments.
          <br />
          <br />
          Contact Us
          <br />
          <br />
          If you have any questions regarding privacy while using the App or
          about our practices, please contact us via email at:
          game.support.link@proton.me.
        </p>

        <a className="link" href="mailto:game.support.link@proton.me">
          game.support.link@proton.me.
        </a>
      </div>
    </div>
  );
}
