import "./_games.scss";
import data from "../../data/games.json";
import Icon from "./lock.svg";
import Button from "../../button/button";
import { useNavigate } from "react-router-dom";

export default function Games() {
  const navigate = useNavigate();

  window.addEventListener("message", (event) => {
    const { action } = event.data;
    if (action === "goBack") {
      navigate(`/menue`);
    }
  });

  const setingsClick = () => {
    console.log("im here");
    window.parent.postMessage({ setingsClick: true }, "*");
  };

  const level = 3846193;
  const handleButtonClick = (id: number) => {
    console.log(id);
    window.parent.postMessage({ gameId: id }, "*");
    (window as any).Android.showToast1(1);
    console.log((window as any).Android.showToast1);
    (window as any).Android.showToast1(id);

    if (
      (window as any).Android &&
      typeof (window as any).Android.showToast === "function"
    ) {
      (window as any).Android.showToast(id);
    } else {
      console.warn("Android.showToast is not available");
    }
  };

  const startButtonClick = () => {
    console.log("im here");
    window.parent.postMessage({ startButton: true }, "*");
  };

  return (
    <div
      className="container games_container"
      style={{ backgroundImage: `url(/background/first-frame-bg.png)` }}
    >
      <div className="game_header">
        <div className="level_container">
          <span className="text">Balance</span>
          <span className="text">{level}</span>
        </div>
        <img src="/buny.png" alt="" className="buny_img" />
        <Button onClick={setingsClick} background="/background/sound_off.png">
          <p></p>
        </Button>
      </div>

      <ul className="list">
        {data.map((game, index) => (
          <li
            key={game.url}
            className={`list_item g${index + 1}`}
            style={{ backgroundImage: `url(${game.image})` }}
            onClick={() => handleButtonClick(index)}
          >
            <div className="text_wrap">
              <p
                className="text"
                dangerouslySetInnerHTML={{ __html: game.text }}
              ></p>
            </div>
            <img src={game.game_img} alt="" className="game_img" />
            {game.image_2 && (
              <img src={game.image_2} alt="" className="game_img_2" />
            )}
          </li>
        ))}
      </ul>
      <div className="wrap">
        <Button onClick={startButtonClick}>START</Button>
      </div>
    </div>
  );
}
