import { useState } from "react";
import Button from "../../button/button";
import "./_login-frame.scss";
import { useNavigate } from "react-router-dom";

const local = "local-email";
export default function LogIn() {
  const [email, setEmail] = useState<string>();
  const navigate = useNavigate();

  const handleBtnClick = () => {
    if (email) {
      localStorage.setItem(local, email);
    }
    navigate("/menue");
  };

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };
  return (
    <div
      className="container log_in"
      style={{ backgroundImage: "url(/background/first-frame-bg.png)" }}
    >
      <div className="content">
        <h2 className="title">Enter email to continue</h2>
        <input type="email" className="email_input" onChange={handleChange} />
        <Button onClick={handleBtnClick} children={"START"}></Button>
        <div className="custom_btn_wrap">
          <Button onClick={handleBtnClick} children={"anonymous mode"}></Button>
        </div>
      </div>
    </div>
  );
}
