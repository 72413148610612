import { useEffect, useState } from "react";
import "./_start-screen.scss";
import Button from "../../button/button";
import { useNavigate } from "react-router-dom";

export default function StartScreen() {
  const [filledRectangles, setFilledRectangles] = useState<number>(0);
  const navigate = useNavigate();

  const handleBtnClick = () => {
    navigate(`/menue`);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (filledRectangles < 12) {
        setFilledRectangles((prev) => prev + 1);
      } else {
        handleBtnClick();
      }
    }, 300); // Інтервал для заповнення кольором
    return () => clearTimeout(timeout);
  }, [filledRectangles]);

  return (
    <div
      className="container start_screen"
      style={{ backgroundImage: "url(/background/first-frame-bg.png)" }}
    >
      <div className="loader">
        <h2 className="title">Welcome</h2>
        <div className="rectangles">
          {[...Array(12)].map((_, index) => (
            <div
              key={index}
              className={`rect ${index < filledRectangles ? "filled" : ""}`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
