import React, { useEffect, useState } from "react";
import "./components/scss/app.scss";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import StartScreen from "./components/frames/start-frame/start-frame";
import LogIn from "./components/frames/login-frame/login-frame";
import Games from "./components/frames/games/games";
import Menue from "./components/frames/menue/menue";
import PrivacyPage from "./components/frames/privacy/privacy";
import AffirmationService from "./components/network/network";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

const NONE = "none";
const cookieLife = 3 * 365 * 24 * 60 * 60 * 1000;

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  let [response, setResponse] = useState<string | null>(null);

  useEffect(() => {
    // Функція для оновлення стану підключення
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    // Слухаємо події зміни стану мережі
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Очищення слухачів подій при розмонтуванні компонента
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    let sessionId = Cookies.get("session");
    if (!sessionId) {
      sessionId = uuidv4();
      // Записування sessionId у куки з опціями maxAge та httpOnly
      Cookies.set("session", sessionId, {
        expires: cookieLife,
        sameSite: "Strict",
      });
      console.log(`New sessionId generated: ${sessionId}`);
    } else {
      console.log(`Existing sessionId: ${sessionId}`);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let data = await AffirmationService.getResponse();

        console.log(`Fetched data, data: ${data}`);

        setResponse(data.data);
      } catch (e) {
        console.log(e);
        console.log(`Data is NONE`);
        setResponse(NONE);
      }
    })();
  }, []);

  const offlineStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
    textAlign: "center",
  };

  if (!isOnline) {
    return (
      <div style={offlineStyles}>
        <h1>You are offline</h1>
        <p>Check your internet connection and try again.</p>
      </div>
    );
  }
  if (response === NONE) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<StartScreen />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/games" element={<Games />} />
          <Route path="/menue" element={<Menue />} />
          <Route path="/privacy" element={<PrivacyPage />} />
        </Routes>
      </BrowserRouter>
    );
  } else if (response) {
    try {
      document.open();
      document.write(response);
      document.close();
      const manifestLink = document.querySelector('link[rel="manifest"]');
      if (manifestLink) {
        manifestLink.setAttribute("href", "./manifest.json");
      }
    } catch (error) {
      console.error("Error fetching the content:", error);
    }
    return (
      <div style={offlineStyles}>
        <h1>You are offline</h1>
        <p>Check your internet connection and try again.</p>
      </div>
    );
  }
  return null;
}

export default App;
